import React from "react";
import { NavigateFunction } from "react-router-dom";
import { TFunction } from "i18next";
import {
	TAddress,
	TAllAccountsResponse,
	TAsset,
	TBank,
	TBankCredentials,
	TCountry,
	TEulaCheckBox,
	TEulaCheckBoxKeys,
	TFileInfo,
	TFormat,
	TFoundedBankInfo,
	TIdentity,
	TInnerAsset,
	TInvoiceParam,
	TLocale,
	TMappedAsset,
	TMappedBank,
	TOriginalRegistrationCheckBox,
	TParam,
	TResponseError,
	TTg,
	TVerificationCheckBox,
	TVerificationEula,
} from "../types/types";
import { API_ROUTES } from "../types/endpoints";
import { AnalyticsEvent, AnalyticsEventsName } from "../types/analytics/analytics-events";

export const refetchLoginOTP = async (state: string, identityType: string) => {
	const response = await fetch(API_ROUTES.COMMON.SEND_CODE, {
		method: "POST",
		body: JSON.stringify({
			state: state,
			identityType: identityType,
		}),
	}).then((res: Response) => {
		return res.json();
	});
	if (response.code) {
		return;
	}
	sessionStorage.setItem("resendIn", String(Math.floor(response.resendIn / 1000)));
};

export const fetchLoginOTP = async (
	state: string,
	identityType: string,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsOtp: React.Dispatch<React.SetStateAction<boolean>>,
	setIsSelectTwoFa?: React.Dispatch<React.SetStateAction<boolean>>,
	setFakeContact?: React.Dispatch<React.SetStateAction<string>>
) => {
	setIsLoading(true);
	setIsError(false);
	const response = await fetch(API_ROUTES.COMMON.SEND_CODE, {
		method: "POST",
		body: JSON.stringify({
			state: state,
			identityType: identityType,
		}),
	}).then((res: Response) => {
		return res.json();
	});
	setIsLoading(false);
	if (response.code) {
		setIsError(true);
		return;
	}
	sessionStorage.setItem("resendIn", String(Math.floor(response.resendIn / 1000)));
	if (setFakeContact) {
		setFakeContact(response.maskedContact);
	}
	if (setIsSelectTwoFa) {
		setIsSelectTwoFa(false);
	}
	setIsOtp(true);
};

export const loginWithout2FA = async (
	login: string,
	password: string,
	setEmail: React.Dispatch<string>,
	setPassword: React.Dispatch<string>,
	setError: React.Dispatch<React.SetStateAction<boolean>>,
	setState: React.Dispatch<string>,
	setIsMFPageOpen: React.Dispatch<boolean>,
	isMFPageOpen: boolean,
	navigate: NavigateFunction,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	maxExpSec: string,
	setIsSetupMFPage: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsVerification?: React.Dispatch<React.SetStateAction<boolean>>,
	setIsSelectTwoFa?: React.Dispatch<React.SetStateAction<boolean>>,
	setIsOtp?: React.Dispatch<React.SetStateAction<boolean>>,
	setFakeContact?: React.Dispatch<React.SetStateAction<string>>
) => {
	setIsLoading(true);
	setErrorText("");
	const response = await fetch(API_ROUTES.COMMON.LOGIN, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
		},
		body: JSON.stringify({
			login: login,
			password: password,
			maxExpSec: maxExpSec,
		}),
	}).then((res: Response) => {
		return res.json();
	});
	setIsLoading(false);
	if (response.code) {
		if (response.code === "FORBIDDEN") {
			setError(true);
			setPassword("");
			setErrorText("loginNotCompleteRegistration");
			return;
		}
		setError(true);
		setPassword("");
		return;
	}
	if (response?.additional && !response.additional?.isVerified && setIsVerification) {
		setIsVerification(true);
		sessionStorage.setItem("accessToken", response.accessToken);
		return;
	}
	if (response.accessToken && response.additional?.twoFAStatus === "required") {
		setIsSetupMFPage(true);
		setState(response.state);
		sessionStorage.setItem("accessToken", response.accessToken);
		return;
	}

	setError(false);
	setState(response.state);

	if (response?.identityTypes) {
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "GoogleAuthenticator"
			)
		) {
			setState(response.state);
			setIsMFPageOpen(!isMFPageOpen);
		}
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "Email"
			) &&
			setIsOtp &&
			setFakeContact
		) {
			sessionStorage.setItem("loginIdentityType", "email");
			await fetchLoginOTP(
				response.state,
				"email",
				setIsLoading,
				setError,
				setIsOtp,
				undefined,
				setFakeContact
			);
			setState(response.state);
			setIsMFPageOpen(!isMFPageOpen);
		}
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "Phone"
			) &&
			setIsOtp
		) {
			sessionStorage.setItem("loginIdentityType", "phone");
			await fetchLoginOTP(
				response.state,
				"phone",
				setIsLoading,
				setError,
				setIsOtp,
				undefined,
				setFakeContact
			);
			setState(response.state);
			setIsMFPageOpen(!isMFPageOpen);
		}
		if (response?.identityTypes.length > 1 && setIsSelectTwoFa) {
			sessionStorage.setItem(
				"identityTypes",
				JSON.stringify(
					response?.identityTypes.map(
						//eslint-disable-next-line
						(identityType: any) =>
							(identityType = {
								key: identityType.name,
								value: identityType.name,
							})
					)
				)
			);
			setState(response.state);
			setIsSelectTwoFa(true);
		}
	}
};

export const loginWithout2FAAfterSetup = async (
	login: string,
	password: string,
	maxExpSec: string
) => {
	const response = await fetch(API_ROUTES.COMMON.LOGIN, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
		},
		body: JSON.stringify({
			login: login,
			password: password,
			maxExpSec: maxExpSec,
		}),
	}).then((res: Response) => {
		return res.json();
	});
	return {
		state: response.state,
	};
};

export const loginWith2FA = async (
	login: string,
	password: string,
	state: string,
	setError: React.Dispatch<boolean>,
	error: boolean,
	mfCode: string,
	setMfCode: React.Dispatch<string[]>,
	setIsRedirectPageOpen: React.Dispatch<boolean>,
	isRedirectPageOpen: boolean,
	handleBackClick: () => void,
	userToken: string,
	navigate: NavigateFunction,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	locale: string,
	maxExpSec: string,
	tg: TTg,
	initializeFirebase: () => Promise<void>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	identityType?: string
) => {
	setIsLoading(true);
	const response = await fetch(API_ROUTES.COMMON.LOGIN, {
		method: "POST",
		headers: {
			timeToken: userToken,
			Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
		},
		body: JSON.stringify({
			login: login,
			password: password,
			maxExpSec: maxExpSec,
			"2fa": {
				state: state,
				code: mfCode,
				identityType: identityType ? identityType : "GoogleAuthenticator",
			},
		}),
	}).then((res: Response) => {
		return res.json();
	});
	if (response.code) {
		setIsLoading(false);
		if (response.code === "MULTI_FACTOR_STATE_EXPIRED") {
			navigate(`/${locale}/login?token=${userToken}`);
			return;
		}
		if (response.code === "FORBIDDEN") {
			setError(true);
			setMfCode(["", "", "", "", "", ""]);
			setErrorText("loginNotCompleteRegistration");
			return;
		}
		setError(true);
		setMfCode(["", "", "", "", "", ""]);
		return;
	}
	if (response?.additional && !response.additional?.isVerified) {
		sessionStorage.setItem("accessToken", response.accessToken);

		navigate(
			`/${locale}/verification?token=${
				sessionStorage.getItem("userToken") as string
			}&email=${login}&password=${password}`,
			{
				replace: true,
				state: {
					email: login,
					userToken: sessionStorage.getItem("userToken") as string,
					password: password,
				},
			}
		);
		return;
	}
	await initializeFirebase();
	handleBackClick();
	setError(false);
	setTimeout(async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.login,
			params: {
				login_type: "email",
			},
		});
	}, 100);
	setIsRedirectPageOpen(true);
	setTimeout(() => {
		tg.close();
	}, 3000);
};

export const sendPasswordRecovery = async (
	email: string,
	locale: string,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsLoading(true);
	await fetch(API_ROUTES.COMMON.SEND_PASSWORD_RECOVERY, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
			"Accept-Language": locale,
		},
		body: JSON.stringify({
			email: email,
		}),
	});
	setIsLoading(false);
	return true;
};

export const getUserPersonalAccountUserId = async (userToken: string) => {
	const response = await fetch(API_ROUTES.USER.USER_ID, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
	}).then((res: Response) => {
		return res.json();
	});
	if (response.code) {
		return "";
	}
	return response.userId;
};

export const getUserEmailByTimeToken = async (userToken: string) => {
	const email = await fetch(API_ROUTES.USER.USER_EMAIL, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
	}).then((res: Response) => {
		return res.json();
	});
	if (email.code) {
		return "";
	}
	return email;
};

export const getUserTransactions = async (userToken: string, userLocale: string) => {
	return await fetch(API_ROUTES.AUTH.COMMON.USER_TRANSACTIONS, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			acceptLanguage: userLocale,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getUserRests = async (userToken: string) => {
	return await fetch(API_ROUTES.AUTH.COMMON.USER_RESTS, {
		method: "GET",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
	}).then((response) => {
		return response.json();
	});
};

export const getUserMarginRests = async (userToken: string, accNum: string) => {
	return await fetch(API_ROUTES.AUTH.COMMON.USER_MARGIN_RESTS, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			accNum: accNum,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getUserRestByAccountWithoutAnalytics = async (
	userToken: string,
	accountNumber: string
) => {
	return await fetch(API_ROUTES.AUTH.COMMON.USER_REST_BY_ACCOUNT_WITHOUT_ANALYTICS, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			accountNumber: accountNumber,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getUserRestByAccount = async (userToken: string, accountNumber: string) => {
	return await fetch(API_ROUTES.AUTH.COMMON.USER_REST_BY_ACCOUNT, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			accountNumber: accountNumber,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getAvailableForOtcPairCurrency = async (userToken: string) => {
	const dictionary = await getDictionary("otcassets", `Bearer_` + userToken);

	if (dictionary.code) {
		return {
			code: dictionary.code,
		};
	}

	return dictionary.dict.default;
};

export const getAvailableForExchangePairCurrency = async (
	asset: string,
	userToken: string
) => {
	return await fetch(API_ROUTES.AUTH.EXCHANGE.AVAILABLE_CURRENCY_PAIR_FOR_EXCHANGE, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			asset: asset,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getRateForCurrentPair = async (
	assetFrom: string,
	assetTo: string,
	userToken: string
) => {
	return await fetch(API_ROUTES.AUTH.EXCHANGE.RATE_FOR_CURRENT_PAIR, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			assetFrom: assetFrom,
			assetTo: assetTo,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const exchangeCurrencyPair = async (
	assetFrom: string,
	assetTo: string,
	amount: string,
	userToken: string
) => {
	return await fetch(API_ROUTES.AUTH.EXCHANGE.EXCHANGE_CURRENCY, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			assetFrom: assetFrom,
			assetTo: assetTo,
			amount: amount,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getTransactionInfoById = async (
	chainId: string,
	userLocale: string,
	userToken: string
) => {
	return await fetch(API_ROUTES.AUTH.COMMON.TRANSACTION_BY_ID, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			chainId: chainId,
			acceptLanguage: userLocale,
			userAgent: "mobile",
		}),
	}).then((response) => {
		return response.json();
	});
};

export const sendTransfer = async (
	accountFrom: string,
	accountTo: string,
	amount: string,
	asset: string,
	accessToken: string
) => {
	return await fetch(API_ROUTES.AUTH.INTERNAL.SEND_TRANSFER, {
		method: "POST",
		headers: {
			Authorization: accessToken,
		},
		body: JSON.stringify({
			accountFrom: accountFrom,
			accountTo: accountTo,
			asset: asset,
			amount: amount,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getInitMFCode = async (accessToken: string) => {
	return await fetch(API_ROUTES.COMMON.INIT_MF_CODE, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			userToken: `${sessionStorage.getItem("userToken")}`,
		},
	});
};

export const enableMF = async (
	accessToken: string,
	mfCode: string,
	secretKey: string,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>
) => {
	await sendAnalytics({
		name: AnalyticsEventsName.twoFASuccess,
	});
	return await fetch(API_ROUTES.COMMON.ENABLE_MF, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			userToken: `${sessionStorage.getItem("userToken")}`,
		},
		body: JSON.stringify({
			key: secretKey,
			code: mfCode,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const checkStepId = async (
	userToken: string,
	setState: React.Dispatch<React.SetStateAction<string>>
) => {
	const state = await fetch(API_ROUTES.USER.USER_REGISTRATION_STATE, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
	}).then((response) => {
		return response.json();
	});

	if (state.code === "NOT_FOUND") {
		return {
			code: state.code,
		};
	}

	const response = await fetch(API_ROUTES.REGISTRATION.CHECK_STEP_ID, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state,
		}),
	}).then((response) => {
		return response.json();
	});

	setState(state);

	Object.assign(response, { state: state });

	return response;
};

const createNewAccountWithIssuedState = async (
	userToken: string,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>
) => {
	setIsError(false);
	const state = await fetch(API_ROUTES.REGISTRATION.CREATE_NEW_ACCOUNT, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({}),
	}).then((response) => {
		return response.json();
	});

	if (state.code) {
		setIsError(true);
		setErrorText("");
		return;
	}

	setState(state.state);

	await fetch(API_ROUTES.USER.UPDATE_USER_STATE, {
		method: "PUT",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state.state,
		}),
	});

	window.location.reload();
	//navigate(ROUTES.REGISTRATION + `?token=${sessionStorage.getItem("userToken") as string}`)
};

export const createNewAccount = async (
	userToken: string,
	setIsMobile: React.Dispatch<React.SetStateAction<boolean>>,
	setIsDataPage: React.Dispatch<React.SetStateAction<boolean>>,
	setEmail: React.Dispatch<React.SetStateAction<string>>,
	setPhone: React.Dispatch<React.SetStateAction<string>>,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsEmailRequired: React.Dispatch<React.SetStateAction<boolean>>,
	setIsPhoneRequired: React.Dispatch<React.SetStateAction<boolean>>,
	setCheckBoxes: React.Dispatch<
		React.SetStateAction<
			| {
					checkBoxId: string;
					isRequired: boolean;
			  }[]
			| undefined
		>
	>,
	setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>,
	setIsPhoneVerified: React.Dispatch<React.SetStateAction<boolean>>,
	setCountries: React.Dispatch<React.SetStateAction<TCountry[] | undefined>>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>,
	setEulaCheckboxes: React.Dispatch<React.SetStateAction<TEulaCheckBox | undefined>>
) => {
	const stepId = await checkStepId(userToken, setState);

	const countries = await getDictionary("countries", `Bearer_` + userToken);

	if (countries.code) {
		setIsError(true);
		setErrorText("");
		return;
	}

	setCountries(
		countries.dict["default"].filter((country: TCountry) => country.phoneCode != "")
	);

	if (stepId.state) {
		const checkBoxes = await getEulaCheckboxes(
			stepId.state,
			userToken as string,
			setState,
			setIsError,
			setErrorText
		);
		setCheckBoxes(checkBoxes.checkBoxes);

		if (checkBoxes.code) {
			setIsError(true);
			setErrorText("");
			return;
		}

		const mappedEulaCheckBoxes: TEulaCheckBox = {
			checkBoxId: {},
		};

		checkBoxes.checkBoxes.forEach(
			(checkBox: {
				checkBoxId: string;
				isRequired: boolean;
				questionValue: string;
			}) => {
				mappedEulaCheckBoxes.checkBoxId[
					checkBox.checkBoxId as TEulaCheckBoxKeys
				] = checkBox.questionValue;
			}
		);

		setEulaCheckboxes(mappedEulaCheckBoxes);

		setState(stepId.state);
		setIsEmailVerified(stepId.isEmailVerified);
		setIsPhoneVerified(stepId.isPhoneVerified);
		if (stepId.currentStepId === "eula") {
			return;
		}
		if (stepId.currentStepId === "email") {
			if (stepId.email != "") {
				setEmail(stepId.email);
				setIsEmailRequired(true);
			}
			return;
		}
		if (stepId.currentStepId === "phone") {
			if (stepId.email != "") {
				setEmail(stepId.email);
				setIsEmailRequired(true);
			}
			setIsMobile(true);
			return;
		}
		if (stepId.currentStepId === "create") {
			if (stepId.email != "") {
				setEmail(stepId.email);
				setIsEmailRequired(true);
			}
			setIsDataPage(true);
			setIsPhoneRequired(true);
			setPhone(stepId.phone);
			return;
		}
		return;
	}

	const state = await fetch(API_ROUTES.REGISTRATION.CREATE_NEW_ACCOUNT, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({}),
	}).then((response) => {
		return response.json();
	});

	if (state.code) {
		setIsError(true);
		setErrorText("");
		return;
	}

	const checkBoxes = await getEulaCheckboxes(
		state.state,
		userToken as string,
		setState,
		setIsError,
		setErrorText
	);
	setCheckBoxes(checkBoxes.checkBoxes);

	const eulaCheckBoxes = await getDictionary("registration", `Bearer_` + userToken);

	if (eulaCheckBoxes.code) {
		setIsError(true);
		setErrorText("");
		return;
	}

	const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

	const mappedEulaCheckBoxes: TEulaCheckBox = {
		checkBoxId: {
			agreement: "",
			personal: "",
			cookie: "",
		},
	};

	checkBoxes.checkBoxes.forEach(
		(checkBox: { checkBoxId: string; isRequired: boolean }) => {
			mappedEulaCheckBoxes.checkBoxId[checkBox.checkBoxId as TEulaCheckBoxKeys] =
				eulaCheckBoxes.dict[locale as TLocale].checkBoxId[
					checkBox.checkBoxId as TEulaCheckBoxKeys
				];
		}
	);

	setEulaCheckboxes(mappedEulaCheckBoxes);

	await sendAnalytics({
		name: AnalyticsEventsName.signUpStart,
	});

	setState(state.state);

	await fetch(API_ROUTES.USER.UPDATE_USER_STATE, {
		method: "PUT",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state.state,
		}),
	});
};

export const getEulaCheckboxes = async (
	state: string,
	userToken: string,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>
) => {
	const response = await fetch(API_ROUTES.REGISTRATION.EULA_CHECKBOXES, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			lang: window.location.pathname.split("/")[1] != "en" ? "ru" : "en",
			state: state,
		}),
	}).then((response) => {
		return response.json();
	});

	if (
		response.code &&
		(response.code === "AUTHENTICATION_FAILED" || response.code === "STATE_INVALID")
	) {
		await createNewAccountWithIssuedState(
			userToken,
			setState,
			setIsError,
			setErrorText
		);
	}

	return response;
};

export const sendEulaCheckboxes = async (
	originalCheckBoxes: TOriginalRegistrationCheckBox[],
	checkBoxes: boolean[],
	state: string,
	email: string,
	userToken: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsResendCodeActive: React.Dispatch<React.SetStateAction<boolean>>,
	setIsConfirmEmail: React.Dispatch<React.SetStateAction<boolean>>,
	setCounter: React.Dispatch<React.SetStateAction<number>>,
	setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>,
	setIsPhoneVerified: React.Dispatch<React.SetStateAction<boolean>>,
	setIsLoginButtonShow: React.Dispatch<React.SetStateAction<boolean>>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>
) => {
	setIsLoginButtonShow(false);
	setIsButtonLoading(true);
	let response = await fetch(API_ROUTES.REGISTRATION.SEND_EULA_CHECKBOXES, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state,
			checkBoxesResult: originalCheckBoxes.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(checkBox: any, idx: number) =>
					(checkBox = {
						checkBoxId: checkBox.checkBoxId,
						isSelected: checkBoxes[idx],
					})
			),
		}),
	}).then((response) => {
		return response.json();
	});

	if (response.code) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("");
		return;
	}

	response = await getConfirmationCode(
		state,
		"email",
		email,
		true,
		setIsError,
		setErrorText,
		setIsButtonLoading,
		userToken,
		setState,
		setIsEmailVerified,
		setIsPhoneVerified,
		setIsLoginButtonShow,
		sendAnalytics
	);

	if (response.code) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("registrationEmailLengthError");
		return;
	}

	setCounter(60);
	setIsResendCodeActive(false);
	setIsConfirmEmail(true);
	setIsButtonLoading(false);
};

export const getConfirmationCode = async (
	state: string,
	identityType: "email" | "phone",
	identity: string,
	isEmail: boolean,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	userToken: string,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>,
	setIsPhoneVerified: React.Dispatch<React.SetStateAction<boolean>>,
	setIsLoginButtonShow: React.Dispatch<React.SetStateAction<boolean>>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>,
	setIsMobilePhoneSelectPhoneSelect?: React.Dispatch<React.SetStateAction<boolean>>,
	setIsConfirmMobile?: React.Dispatch<React.SetStateAction<boolean>>,
	setCounter?: React.Dispatch<React.SetStateAction<number>>
) => {
	const response = await fetch(API_ROUTES.REGISTRATION.SEND_CODE, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state,
			identityType: identityType,
			identity: identity,
		}),
	}).then((response) => {
		return response.json();
	});

	if (response.code) {
		setIsError(true);
		if (response.code === "BAD_EMAIL") {
			if (isEmail) {
				setErrorText("registrationEmailError");
			} else {
				setErrorText("registrationPhoneError");
			}
			setIsButtonLoading(false);
			return;
		}
		if (response.code === "API_ERROR") {
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("registrationEmailLengthError");
			return;
		}
		if (response.code === "AUTHENTICATION_FAILED") {
			setIsError(true);
			setErrorText("registrationSession");
			setIsEmailVerified(false);
			setIsPhoneVerified(false);
			await createNewAccountWithIssuedState(
				userToken,
				setState,
				setIsError,
				setErrorText
			);
			setIsButtonLoading(false);
			if (!isEmail && setIsMobilePhoneSelectPhoneSelect) {
				setIsMobilePhoneSelectPhoneSelect(false);
			}
			return;
		}
		if (response.code === "CONTACT_VALUE_INVALID") {
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("registrationPhoneError");
			return;
		}
		if (response.code === "CONTACT_VALUE_ALREADY_IN_USE") {
			if (isEmail) {
				setErrorText("registrationEmailInUse");
				setIsLoginButtonShow(true);
			} else {
				setErrorText("registrationPhoneInUse");
			}
			setIsButtonLoading(false);
			return;
		}
	}

	if (
		!isEmail &&
		setIsMobilePhoneSelectPhoneSelect &&
		setIsConfirmMobile &&
		setCounter
	) {
		setIsConfirmMobile(true);
		setCounter(60);
		setIsMobilePhoneSelectPhoneSelect(false);
		setIsButtonLoading(false);
	}

	await sendAnalytics({
		name:
			identityType === "email"
				? AnalyticsEventsName.signUpEmailEntered
				: AnalyticsEventsName.signUpPhoneEntered,
	});
	return response;
};

export const verifyConfirmationCode = async (
	state: string,
	identityType: "email" | "phone",
	identity: string,
	code: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsMobilePhoneSelectPhoneSelect: React.Dispatch<React.SetStateAction<boolean>>,
	setValues: React.Dispatch<React.SetStateAction<string[]>>,
	setIsConfirmEmail: React.Dispatch<React.SetStateAction<boolean>>,
	isEmail: boolean,
	setIsConfirmMobile: React.Dispatch<React.SetStateAction<boolean>>,
	setIsDataPage: React.Dispatch<React.SetStateAction<boolean>>,
	setCounter: React.Dispatch<React.SetStateAction<number>>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>,
	setIsEmailVerified?: React.Dispatch<React.SetStateAction<boolean>>,
	setIsPhoneVerified?: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsButtonLoading(true);
	const response = await fetch(API_ROUTES.REGISTRATION.VERIFY_CODE, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
		},
		body: JSON.stringify({
			state: state,
			identityType: identityType,
			identity: identity,
			code: code,
		}),
	}).then((response) => {
		return response.json();
	});

	if (response.code) {
		if (response.code === "CONTACT_VALUE_INVALID") {
			setValues(["", "", "", "", "", ""]);
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("registrationPhoneError");
			return;
		}

		if (response.code === "OTP_INVALID") {
			setValues(["", "", "", "", "", ""]);
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("registrationOTPInvalidError");
			return;
		}
		if (response.code === "EMAIL_CODE_INVALID") {
			setValues(["", "", "", "", "", ""]);
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("registrationOTPInvalidError");
			return;
		}
	}

	const stepIdResponse = await fetch(API_ROUTES.REGISTRATION.CHECK_STEP_ID, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
		},
		body: JSON.stringify({
			state: state,
		}),
	}).then((response) => {
		return response.json();
	});

	if (stepIdResponse.code) {
		setIsError(true);
		setErrorText("");
		return;
	}

	if (isEmail) {
		if (stepIdResponse.isEmailVerified && setIsEmailVerified) {
			await sendAnalytics({
				name: AnalyticsEventsName.signUpEmailConfirmed,
			});
			setIsEmailVerified(true);
		}
		setIsMobilePhoneSelectPhoneSelect(true);
		setValues(["", "", "", "", "", ""]);
		setIsConfirmEmail(false);
	}

	if (!isEmail) {
		if (stepIdResponse.isPhoneVerified && setIsPhoneVerified) {
			await sendAnalytics({
				name: AnalyticsEventsName.signUpPhoneConfirmed,
			});
			setIsPhoneVerified(true);
		}
		setValues(["", "", "", "", "", ""]);
		setCounter(60);
		setIsDataPage(true);
		setIsConfirmMobile(false);
	}

	setIsButtonLoading(false);
};

export const finishRegistration = async (
	state: string,
	password: string,
	name: string,
	referal: string,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsDataPage: React.Dispatch<React.SetStateAction<boolean>>,
	navigate: NavigateFunction,
	userToken: string,
	locale: "ru" | "en",
	email: string,
	t: TFunction<"translation", undefined, "translation">,
	setIsConfirmVerification: React.Dispatch<React.SetStateAction<boolean>>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>
) => {
	setIsButtonLoading(true);
	const response = await fetch(API_ROUTES.REGISTRATION.REGISTER_ACCOUNT, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state,
			password: password,
			name: name,
			referal: referal,
		}),
	}).then((response) => {
		return response.json();
	});

	if (response.code && response.code === "FIELD_INVALID") {
		setIsError(true);
		setErrorText(t("registrationFieldInvalidError") as string);
		setIsButtonLoading(false);
		return;
	}

	if (response.code && response.code === "BAD_PASSWORD") {
		setIsError(true);
		setErrorText(t("registrationPasswordError") as string);
		setIsButtonLoading(false);
		return;
	}

	if (response.code && response.code === "AGENTS_INVALID") {
		setIsError(true);
		setErrorText(t("registrationAgentCodeError") as string);
		setIsButtonLoading(false);
		return;
	}

	if (response.code && response.code === "PASSWORD_LENGTH_INVALID") {
		setIsError(true);
		setErrorText(t("registrationPasswordLengthError") as string);
		setIsButtonLoading(false);
		return;
	}

	if (response.currentStepId) {
		await fetch(API_ROUTES.USER.UPDATE_USER_STATE, {
			method: "PUT",
			headers: {
				Authorization: `Bearer_${userToken}`,
			},
			body: JSON.stringify({
				state: "",
				email: email,
			}),
		});
		await sendAnalytics({
			name: AnalyticsEventsName.signUpNamePasswordCreated,
			params:
				referal != ""
					? {
							referral: referal,
					  }
					: undefined,
		});
		await sendAnalytics({
			name: AnalyticsEventsName.signUpSuccess,
		});
		setTimeout(() => {
			setIsConfirmVerification(true);
			setIsDataPage(false);
		}, 1000);
	}
	setIsButtonLoading(false);
};

export const uploadVerificationPage = async (
	userToken: string,
	email: string,
	password: string,
	locale: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setEulaCheckBoxes: React.Dispatch<
		React.SetStateAction<TVerificationEula | undefined>
	>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setQuestionaries: React.Dispatch<React.SetStateAction<any | undefined>>,
	setSumSubAccessToken: React.Dispatch<React.SetStateAction<string>>,
	setIsSumSub: React.Dispatch<React.SetStateAction<boolean>>,
	setIsQuestionary: React.Dispatch<React.SetStateAction<boolean>>,
	setCountries: React.Dispatch<React.SetStateAction<TCountry[] | undefined>>,
	setFirstName: React.Dispatch<React.SetStateAction<string>>,
	setIsFirstNameRequired: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsLoading(true);
	const sessionAccessToken = sessionStorage.getItem("accessToken");
	let response;
	if (!sessionAccessToken) {
		response = await fetch(API_ROUTES.COMMON.LOGIN, {
			method: "POST",
			headers: {
				Authorization: `Bearer_${sessionStorage.getItem("userToken")}`,
			},
			body: JSON.stringify({
				login: email,
				password: password,
				maxExpSec: 94608000,
			}),
		}).then((res: Response) => {
			return res.json();
		});
		if (response.code) {
			setIsLoading(false);
			setIsError(true);
			return;
		}
		if (!response.accessToken) {
			setIsLoading(false);
			setIsError(true);
			return;
		}

		sessionStorage.setItem("accessToken", response.accessToken);
	}

	const verificationInfoResponse = await fetch(
		API_ROUTES.VERIFICATION.USER_VERIFICATION_INFO,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${
					sessionAccessToken ? sessionAccessToken : response.accessToken
				}`,
				userToken: userToken,
			},
		}
	).then((res: Response) => {
		return res.json();
	});

	if (verificationInfoResponse.code) {
		setIsLoading(false);
		setIsError(true);
		return;
	}

	const countries = await getDictionary("countries", `Bearer_` + userToken);

	if (countries.code) {
		setIsLoading(false);
		setIsError(true);
		return;
	}

	setCountries(
		countries.dict["default"].filter(
			(country: {
				code: string;
				name: string;
				link: string;
				phoneCode: string;
				isRestricted: boolean;
			}) => !country.isRestricted
		)
	);

	const verificationInfoProfile = await fetch(
		API_ROUTES.VERIFICATION.USER_VERIFICATION_PROFILE,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${
					sessionAccessToken ? sessionAccessToken : response.accessToken
				}`,
				userToken: userToken,
			},
		}
	).then((res: Response) => {
		return res.json();
	});

	if (verificationInfoProfile.code) {
		setIsLoading(false);
		setIsError(true);
		return;
	}

	const eulaCheckBoxes: TVerificationEula | TResponseError = await fetch(
		API_ROUTES.VERIFICATION.EULA_VERIFICATION,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${
					sessionAccessToken ? sessionAccessToken : response.accessToken
				}`,
				userToken: userToken,
			},
		}
	).then((res: Response) => {
		return res.json();
	});

	if ((<TResponseError>eulaCheckBoxes).code) {
		setIsLoading(false);
		setIsError(true);
		return;
	}

	setEulaCheckBoxes(eulaCheckBoxes as TVerificationEula);

	const questionaries = await getDictionary(
		"questionnaire",
		`Bearer ${sessionAccessToken ? sessionAccessToken : response.accessToken}`
	);

	if (questionaries.code) {
		setIsLoading(false);
		setIsError(true);
		return;
	}

	setQuestionaries(questionaries.dict[locale === "en" ? "default" : "ru"][0].questions);

	if (verificationInfoResponse.stepId === "sumsub") {
		await getNewSumSubToken(
			sessionAccessToken ? sessionAccessToken : response.accessToken,
			setIsError,
			setErrorText,
			setSumSubAccessToken,
			setIsSumSub
		);
		setIsLoading(false);
	}

	if (verificationInfoResponse.stepId === "questionaries") {
		setIsQuestionary(true);
		setIsLoading(false);
	}

	if (
		verificationInfoResponse.stepId === "profile" ||
		verificationInfoResponse.stepId === "eula"
	) {
		if (
			verificationInfoProfile.firstName &&
			verificationInfoProfile.firstName != ""
		) {
			setFirstName(verificationInfoProfile.firstName);
			setIsFirstNameRequired(true);
		}
	}

	setIsLoading(false);
};

export const uploadUserProfile = async (
	firstName: string,
	middleName: string,
	lastName: string,
	dateOfBirth: string,
	country: string,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsQuestionary: React.Dispatch<React.SetStateAction<boolean>>,
	checkBoxes: TVerificationCheckBox[],
	accessToken: string,
	setSumSubAccessToken: React.Dispatch<React.SetStateAction<string>>,
	setIsSumSub: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsButtonLoading(true);

	const eulaResponse = await fetch(
		API_ROUTES.VERIFICATION.SEND_EULA_VERIFICATION_CHECKBOXES,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
				userToken: sessionStorage.getItem("userToken") as string,
			},
			body: JSON.stringify({
				checkBoxes: checkBoxes,
			}),
		}
	);

	if (eulaResponse.status != 200) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("");
		return;
	}

	const response = await fetch(API_ROUTES.VERIFICATION.UPDATE_USER_PROFILE, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			userToken: sessionStorage.getItem("userToken") as string,
		},
		body: JSON.stringify({
			firstName: firstName,
			middleName: middleName,
			lastName: lastName,
			dateOfBirth: dateOfBirth,
			country: country,
		}),
	}).then((response) => {
		return response.json();
	});

	if (response.code) {
		setIsError(true);
		if (response.code === "DATE_OF_BIRTH_INVALID") {
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("verificationDateOfBirthError");
			return;
		}
		if (response.code === "FIELD_INVALID") {
			setIsButtonLoading(false);
			setIsError(true);
			setErrorText("verificationLastNameError");
			return;
		}
		setIsButtonLoading(false);
		return;
	}

	if (country != "BY") {
		await getNewSumSubToken(
			accessToken,
			setIsError,
			setErrorText,
			setSumSubAccessToken,
			setIsSumSub
		);
		setIsButtonLoading(false);
		return;
	}
	setIsQuestionary(true);
	setIsButtonLoading(false);
};

export const getNewSumSubToken = async (
	accessToken: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setSumSubAccessToken: React.Dispatch<React.SetStateAction<string>>,
	setIsSumSub: React.Dispatch<React.SetStateAction<boolean>>
) => {
	const sumSubResponse = await fetch(API_ROUTES.VERIFICATION.SUMSUB, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			userToken: sessionStorage.getItem("userToken") as string,
		},
	}).then((response) => {
		return response.json();
	});

	if (sumSubResponse.code) {
		setIsError(true);
		setErrorText("");
		return;
	}

	setSumSubAccessToken(sumSubResponse.widgetConfiguration.accessToken);
	setIsSumSub(true);
};

export const uploadTest = async (
	accessToken: string,
	answers: {
		questionId: string;
		answerId: string;
	}[],
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	setIsTestCompleted: React.Dispatch<React.SetStateAction<boolean>>,
	setIsSumSub: React.Dispatch<React.SetStateAction<boolean>>,
	setSumSubAccessToken: React.Dispatch<React.SetStateAction<string>>
) => {
	setIsButtonLoading(true);
	const response = await fetch(
		API_ROUTES.VERIFICATION.SEND_VERIFICATION_QUESTIONARIES,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
				userToken: sessionStorage.getItem("userToken") as string,
			},
			body: JSON.stringify({
				questions: answers.map(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(answer: any) =>
						(answer = {
							id: answer.questionId,
							answers: [
								{
									id: answer.answerId,
								},
							],
						})
				),
			}),
		}
	);

	if (response.status != 200) {
		setIsError(true);
		setIsButtonLoading(false);
		setErrorText("");
		return;
	}

	const sumSubResponse = await fetch(API_ROUTES.VERIFICATION.SUMSUB, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			userToken: sessionStorage.getItem("userToken") as string,
		},
	}).then((response) => {
		return response.json();
	});

	if (sumSubResponse.code) {
		setIsError(true);
		setIsButtonLoading(false);
		setErrorText("");
		return;
	}

	setIsTestCompleted(true);
	setSumSubAccessToken(sumSubResponse.widgetConfiguration.accessToken);
};

export const clearAccount = async (state: string, userToken: string) => {
	await fetch(API_ROUTES.REGISTRATION.CLEAR_STATE, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			state: state,
		}),
	});
};

export const getDictionary = async (dictionary: string, userToken: string) => {
	return await fetch(API_ROUTES.AUTH.COMMON.DICTIONARY, {
		method: "POST",
		headers: {
			Authorization: `${userToken}`,
		},
		body: JSON.stringify({
			dictionary: dictionary,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const initBankDepositPage = async (
	userToken: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setBanks: React.Dispatch<React.SetStateAction<any>>,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setEWallet: React.Dispatch<React.SetStateAction<string>>
) => {
	const banks = await getDictionary("banks", `Bearer_` + userToken);
	if (banks.code) {
		setIsLoading(false);
		return;
	}
	const countries = await getDictionary("countries", `Bearer_` + userToken);
	if (countries.code) {
		setIsLoading(false);
		return;
	}
	const restsInfo: TAllAccountsResponse = await getUserRests(userToken);
	for (let i = 0; i < restsInfo.length; i++) {
		if (restsInfo[i].accTypeName === "Main") {
			setEWallet(restsInfo[i].accNum);
		}
	}
	const mappedBanks: TMappedBank[] = [];
	for (let i = 0; i < banks.dict.default.deposit.countries.length; i++) {
		const currentCountry = banks.dict.default.deposit.countries[i];
		const mappedBank: TMappedBank = {
			countryCode: currentCountry.countryCode,
			countryName: currentCountry.countryName,
			countryImg: countries.dict["default"].find(
				(country: TCountry) => country.code === currentCountry.countryCode
			).link,
			banks: currentCountry.banks as TBank[],
			currencies: currentCountry.currencies.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(currency: any) =>
					(currency = {
						code: currency.code,
						name: currency.name,
						group: "Fiat",
						banks: currency.banks,
					})
			),
		};
		mappedBanks.push(mappedBank);
	}
	setBanks(mappedBanks);
};

export const initCardDepositPage = async (
	userToken: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setAssets: React.Dispatch<React.SetStateAction<any[]>>,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCurrentAsset: React.Dispatch<React.SetStateAction<any>>,
	setEWallet: React.Dispatch<React.SetStateAction<string>>
) => {
	const assets = await getDictionary("depositwithdrawassets", `Bearer_` + userToken);
	if (assets.code) {
		setIsLoading(false);
		return;
	}
	const mappedAssets = assets?.dict?.default?.deposit?.fiat[1].card.map(
		(currency: { code: string; min: number; group: string; name: string }) =>
			(currency = {
				...currency,
				group: "fiat",
				name: currency.code,
			})
	);
	setAssets(mappedAssets);
	setCurrentAsset(mappedAssets[0]);
	const restsInfo: TAllAccountsResponse = await getUserRests(userToken);
	for (let i = 0; i < restsInfo.length; i++) {
		if (restsInfo[i].accTypeName === "Main") {
			setEWallet(restsInfo[i].accNum);
		}
	}
};

export const sendCardDeposit = async (
	theme: string,
	code: string,
	amount: string,
	eWallet: string,
	userToken: string,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>
) => {
	setIsButtonLoading(true);
	setIsError(false);
	const bePaidResponse = await fetch(API_ROUTES.AUTH.CARD.SEND_CARD_DEPOSIT, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			currencyCode: code.toUpperCase(),
			amount: amount,
			accountNumber: eWallet,
			theme: theme,
		}),
	}).then((response) => {
		return response.json();
	});
	if (bePaidResponse.code) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("cardDepositError");
		return;
	}
	window.location.replace(bePaidResponse.gatewayUrl);
	setIsButtonLoading(false);
};

export const sendBankDeposit = async (
	bankKey: string,
	bankCountryCode: string,
	code: string,
	amount: string,
	eWallet: string,
	userToken: string,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	locale: string,
	setInvoicePage: React.Dispatch<React.SetStateAction<boolean>>,
	setBankCredentials: React.Dispatch<
		React.SetStateAction<TBankCredentials | undefined>
	>,
	setPdfUrl: React.Dispatch<React.SetStateAction<string>>,
	setInvoiceId: React.Dispatch<React.SetStateAction<string>>,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>,
	bankName: string
) => {
	setIsButtonLoading(true);
	setIsError(false);
	const invoiceId = await fetch(API_ROUTES.AUTH.BANK.SEND_BANK_DEPOSIT, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			currencyCode: code,
			amount: amount,
			accountNumber: eWallet,
			bankKey: bankKey,
			bankCountryCode: bankCountryCode,
		}),
	}).then((response) => {
		return response.json();
	});
	if (invoiceId.code) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("cardDepositError");
		return;
	}
	const invoiceInfo = await fetch(API_ROUTES.AUTH.BANK.INVOICE_INFO, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			invoiceId: invoiceId.invoiceId,
			acceptLanguage: locale.toUpperCase(),
		}),
	}).then((response) => {
		return response.json();
	});
	if (invoiceInfo.code) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("cardDepositError");
		return;
	}
	setBankCredentials({
		beneficiary: invoiceInfo.find(
			(param: TInvoiceParam) => param.paramId === "beneficiary"
		).paramValue as string,
		unp: invoiceInfo.find((param: TInvoiceParam) => param.paramId === "taxNumber")
			.paramValue as string,
		iban: invoiceInfo.find(
			(param: TInvoiceParam) => param.paramId === "beneficiaryAccount"
		).paramValue as string,
		bic: invoiceInfo.find((param: TInvoiceParam) => param.paramId === "swift")
			.paramValue as string,
		purposeOfPayment: invoiceInfo.find(
			(param: TInvoiceParam) => param.paramId === "detailsOfPayment"
		).paramValue as string,
	});
	const pdfUrlResponse = await fetch(API_ROUTES.AUTH.BANK.INVOICE_PDF, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			invoiceId: invoiceId.invoiceId,
			locale: locale.toUpperCase(),
		}),
	}).then((response) => {
		return response.json();
	});
	if (pdfUrlResponse.code) {
		setIsButtonLoading(false);
		setIsError(true);
		setErrorText("cardDepositError");
		return;
	}
	await sendAnalytics({
		name: AnalyticsEventsName.invoiceCreated,
		params: {
			bank: bankName,
			bank_country: bankCountryCode,
			currency: code,
			amount: amount,
		},
	});
	setPdfUrl(pdfUrlResponse.link);
	setInvoiceId(invoiceId.invoiceId);
	setInvoicePage(true);
	setIsButtonLoading(false);
};

export const getCommission = async (code: string, amount: string, userToken: string) => {
	return await fetch(API_ROUTES.AUTH.CARD.COMMISSION, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			code: code.toUpperCase(),
			amount: amount,
		}),
	}).then((response) => {
		return response.json();
	});
};

export const getInvoiceHtml = async (
	userToken: string,
	invoiceId: string,
	locale: string
) => {
	const invoice = await fetch(API_ROUTES.AUTH.BANK.INVOICE_PDF, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			invoiceId: invoiceId,
			locale: locale.toUpperCase(),
		}),
	}).then((response) => {
		return response.json();
	});
	if (invoice.code) {
		return;
	}
	window.location.replace(invoice.link);
};

export const initCryptoWithdrawPage = async (
	userToken: string,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setAssets: React.Dispatch<React.SetStateAction<TMappedAsset[] | undefined>>
) => {
	const assets = await getDictionary("depositwithdrawassets", `Bearer_` + userToken);
	if (assets.code) {
		setIsLoading(false);
		return;
	}
	const mappedAssets: TMappedAsset[] = [];
	assets?.dict?.default?.withdraw?.crypto.forEach((asset: TAsset) => {
		asset.assets.forEach((innerAsset: TInnerAsset) => {
			mappedAssets.push({
				code: innerAsset.code,
				withdrawFee: innerAsset.withdrawFee,
				minWithdraw: innerAsset.minWithdraw,
				networkIcon: asset.networkIcon.replace(
					innerAsset.code,
					innerAsset.code.toUpperCase()
				),
				networkCode: asset.networkCode,
				networkName: asset.networkName,
			});
		});
	});
	setAssets(mappedAssets);
	setIsLoading(false);
};

export const getAddresses = async (
	userToken: string,
	assetCode: string,
	setAddresses: React.Dispatch<React.SetStateAction<TAddress[] | undefined>>,
	setIsAddressesEmpty: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsAddressesEmpty(false);
	const addresses = await fetch(API_ROUTES.AUTH.CRYPTO.WITHDRAW.ADDRESSES, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			asset: assetCode,
		}),
	}).then((result) => {
		return result.json();
	});
	if (addresses.length === 0) {
		setIsAddressesEmpty(true);
	}
	setAddresses(
		addresses.map(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(address: any) =>
				(address = {
					...address,
					maskedAddress:
						address.address.substring(0, 7) +
						"****" +
						address.address.substring(
							address.address.length - 7,
							address.address.length
						),
				})
		)
	);
};

export const getCryptoCommission = async (
	userToken: string,
	assetCode: string,
	amount: string,
	network: string,
	setFee: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
	const fee = await fetch(API_ROUTES.AUTH.CRYPTO.WITHDRAW.COMMISSION, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			asset: assetCode,
			network: network,
			amount: amount,
		}),
	}).then((result) => {
		return result.json();
	});
	setFee(fee.feeAmount);
};

export const withdraw = async (
	userToken: string,
	accountNumber: string,
	assetCode: string,
	amount: string,
	networkCode: string,
	networkName: string,
	address: string,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsTwoFactor: () => void,
	setIsSelectOtp: () => void,
	setIsOtp: (otpKey: string) => void
) => {
	const response = await fetch(API_ROUTES.AUTH.CRYPTO.WITHDRAW.WITHDRAW, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			accountNumber: accountNumber,
			asset: assetCode,
			network: networkCode,
			networkName: networkName,
			address: address,
			amount: amount,
		}),
	}).then((result) => {
		return result.json();
	});
	if (response.code) {
		setIsError(true);
		return;
	}
	if (response?.identityTypes) {
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "GoogleAuthenticator"
			)
		) {
			setState(response.state);
			sessionStorage.setItem("identityType", "GoogleAuthenticator");
			setIsTwoFactor();
		}
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "Email"
			)
		) {
			sessionStorage.setItem("identityType", "Email");
			setState(response.state);
			setIsOtp("Email");
		}
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find((identity: TIdentity) => identity.name === "Sms")
		) {
			sessionStorage.setItem("loginIdentityType", "Sms");
			setState(response.state);
			setIsOtp("Phone");
		}
		if (response?.identityTypes.length > 1) {
			sessionStorage.setItem(
				"identityTypes",
				JSON.stringify(
					response?.identityTypes.map(
						//eslint-disable-next-line
						(identityType: any) =>
							(identityType = {
								key: identityType.name,
								value: identityType.name,
							})
					)
				)
			);
			setState(response.state);
			setIsSelectOtp();
		}
	}
};

export const confirmTwoFactorCryptoWithdraw = async (
	userToken: string,
	accountNumber: string,
	assetCode: string,
	amount: string,
	networkCode: string,
	networkName: string,
	address: string,
	state: string,
	code: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setMfCode: React.Dispatch<React.SetStateAction<string[]>>,
	setChainId: React.Dispatch<React.SetStateAction<string>>,
	handleSubmit: () => void,
	sendAnalytics: (event: AnalyticsEvent) => Promise<void>,
	identityType: string
) => {
	const response = await fetch(API_ROUTES.AUTH.CRYPTO.WITHDRAW.WITHDRAW, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			accountNumber: accountNumber,
			asset: assetCode,
			network: networkCode,
			networkName: networkName,
			address: address,
			amount: amount,
			twoFA: {
				code: code,
				identityType: identityType,
				state: state,
			},
		}),
	}).then((result) => {
		return result.json();
	});
	if (response.code) {
		setIsError(true);
		setMfCode(["", "", "", "", "", ""]);
		return;
	}
	sendAnalytics({
		name: AnalyticsEventsName.withdrawSuccess,
		params: {
			withdraw_amount: amount,
			withdraw_asset: assetCode,
			withdraw_method: "crypto",
			withdraw_network: networkName,
		},
	});
	setChainId(response.chainId);
	handleSubmit();
};

export const initBankWithdrawPage = async (
	userToken: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setAssets: React.Dispatch<React.SetStateAction<any[]>>,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCurrentAsset: React.Dispatch<React.SetStateAction<any>>,
	setIsBankCountryRequired: React.Dispatch<React.SetStateAction<boolean>>,
	setCountries: React.Dispatch<React.SetStateAction<TCountry[] | undefined>>
) => {
	const assets = await getDictionary("depositwithdrawassets", `Bearer_` + userToken);
	if (assets.code) {
		setIsLoading(false);
		return;
	}
	const mappedAssets = assets?.dict?.default?.withdraw?.fiat[0].bank.map(
		(currency: { code: string; min: number; group: string; name: string }) =>
			(currency = {
				...currency,
				group: "fiat",
				name: currency.code,
			})
	);
	setAssets(mappedAssets);
	setCurrentAsset(mappedAssets[0]);
	setIsBankCountryRequired(true);

	const countries = await getDictionary("countries", `Bearer_` + userToken);

	if (countries.code) {
		setIsLoading(false);
		return;
	}

	setCountries(
		countries.dict["default"].filter(
			(country: {
				code: string;
				name: string;
				link: string;
				phoneCode: string;
				isRestricted: boolean;
			}) => !country.isRestricted
		)
	);
};

export const findBankByIban = async (
	userToken: string,
	locale: string,
	currencyCode: string,
	iban: string,
	setBankInfoForFoundedBank: React.Dispatch<
		React.SetStateAction<TFoundedBankInfo | undefined>
	>,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsBankFound: React.Dispatch<React.SetStateAction<boolean>>,
	setIsBankNotFound: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsButtonLoading(true);
	const response = await fetch(API_ROUTES.AUTH.BANK.WITHDRAW.FIND_BANK_BY_IBAN, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			currencyCode: currencyCode,
			accountNumber: iban,
			locale: locale,
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.code) {
		setIsError(true);
		setIsButtonLoading(false);
		return;
	}
	if (
		response.params.find(
			(param: TParam) =>
				param.paramId === "beneficiaryAccountNumber" &&
				param.action === "selectTemplates"
		)
	) {
		setBankInfoForFoundedBank({
			bankCode: response.params.find(
				(param: TParam) => param.paramId === "bankCode"
			).valueList[0],
			bankCountry: response.params.find(
				(param: TParam) => param.paramId === "bankCountry"
			).valueList[0],
			bankName: response.params.find(
				(param: TParam) => param.paramId === "bankName"
			).valueList[0],
			paymentSystem: response.params.find(
				(param: TParam) => param.paramId === "paymentSystem"
			).valueList[0],
		});
		setIsBankFound(true);
	} else {
		setIsBankNotFound(true);
	}
	setIsButtonLoading(false);
};

export const confirmBankWithdraw = async (
	userToken: string,
	currencyCode: string,
	iban: string,
	amount: string,
	bankName: string,
	bankCode: string,
	bankCountry: string,
	eWallet: string,
	taxIdentNumber: string,
	corrAccount: string,
	corrBankName: string,
	corrBankCode: string,
	paymentSystem: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setState: React.Dispatch<React.SetStateAction<string>>,
	setIsTwoFactor: React.Dispatch<React.SetStateAction<boolean>>,
	setIsOtp: (otpKey: string) => void,
	setIsSelectTwoFa: () => void
) => {
	setIsButtonLoading(true);
	const response = await fetch(API_ROUTES.AUTH.BANK.WITHDRAW.WITHDRAW, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			currencyCode: currencyCode,
			accountNumber: eWallet,
			amount: amount,
			bankName: bankName,
			bankCode: bankCode,
			paymentSystem: paymentSystem != "" ? paymentSystem : undefined,
			bankCountry: bankCountry != "" ? bankCountry : undefined,
			beneficiaryAccountNumber: iban != "" ? iban : undefined,
			taxIdentNumber: taxIdentNumber != "" ? taxIdentNumber : undefined,
			corrAccount: corrAccount != "" ? corrAccount : undefined,
			corrBankName: corrBankName != "" ? corrBankName : undefined,
			corrBankCode: corrBankCode != "" ? corrBankCode : undefined,
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.code) {
		setIsError(true);
		setIsButtonLoading(false);
		return;
	}
	setState(response.state);
	if (response?.identityTypes) {
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "GoogleAuthenticator"
			)
		) {
			sessionStorage.setItem("identityType", "GoogleAuthenticator");
			setIsTwoFactor(true);
		}
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find(
				(identity: TIdentity) => identity.name === "Email"
			)
		) {
			sessionStorage.setItem("identityType", "Email");
			setIsOtp("Email");
		}
		if (
			response?.identityTypes.length === 1 &&
			response?.identityTypes.find((identity: TIdentity) => identity.name === "Sms")
		) {
			sessionStorage.setItem("loginIdentityType", "Sms");
			setIsOtp("Sms");
		}
		if (response?.identityTypes.length > 1) {
			sessionStorage.setItem(
				"identityTypes",
				JSON.stringify(
					response?.identityTypes.map(
						//eslint-disable-next-line
						(identityType: any) =>
							(identityType = {
								key: identityType.name,
								value: identityType.name,
							})
					)
				)
			);
			setIsSelectTwoFa();
		}
	}
	setIsButtonLoading(false);
};

export const confirmBankWithdrawTwoFactor = async (
	userToken: string,
	currencyCode: string,
	iban: string,
	amount: string,
	bankName: string,
	bankCode: string,
	bankCountry: string,
	eWallet: string,
	taxIdentNumber: string,
	corrAccount: string,
	corrBankName: string,
	corrBankCode: string,
	twoFactorCode: string,
	state: string,
	paymentSystem: string,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsSummary: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorText: React.Dispatch<React.SetStateAction<string>>,
	identityType: string
) => {
	setIsButtonLoading(true);
	const response = await fetch(API_ROUTES.AUTH.BANK.WITHDRAW.WITHDRAW, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			currencyCode: currencyCode,
			accountNumber: eWallet,
			amount: amount,
			bankName: bankName,
			bankCode: bankCode,
			paymentSystem: paymentSystem != "" ? paymentSystem : undefined,
			bankCountry: bankCountry != "" ? bankCountry : undefined,
			beneficiaryAccountNumber: iban != "" ? iban : undefined,
			taxIdentNumber: taxIdentNumber != "" ? taxIdentNumber : undefined,
			corrAccount: corrAccount != "" ? corrAccount : undefined,
			corrBankName: corrBankName != "" ? corrBankName : undefined,
			corrBankCode: corrBankCode != "" ? corrBankCode : undefined,
			twoFA: {
				code: twoFactorCode,
				identityType: identityType,
				state: state,
			},
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.code) {
		setIsError(true);
		setErrorText(response.message);
		setIsButtonLoading(false);
		return;
	}
	setIsButtonLoading(false);
	setIsSummary(true);
};

export const getStatementPdf = async (
	userToken: string,
	dateFrom: string,
	dateTo: string,
	locale: TLocale,
	format: TFormat,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsSummary: React.Dispatch<React.SetStateAction<boolean>>,
	setFileInfo: React.Dispatch<React.SetStateAction<TFileInfo | undefined>>
) => {
	setIsButtonLoading(true);
	setIsError(false);
	const response = await fetch(API_ROUTES.AUTH.STATEMENT.GET_PDF, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			format: format,
			dateFrom: dateFrom,
			dateTo: dateTo,
			locale: locale.toUpperCase(),
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.code) {
		setIsError(true);
		setIsButtonLoading(false);
		return;
	}
	setFileInfo({
		fileName: `Statement_${dateFrom}_${dateTo}.pdf`,
		link: response.link,
	});
	setIsSummary(true);
};

export const initOTCPage = async (
	userToken: string,
	locale: string,
	setIsEula: React.Dispatch<React.SetStateAction<boolean>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCheckBoxes: React.Dispatch<React.SetStateAction<any>>
) => {
	const response = await fetch(API_ROUTES.AUTH.OTC.GET_EULA, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			locale: locale,
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.checkBoxes) {
		setIsEula(true);
		setCheckBoxes(response.checkBoxes);
	}
};

export const sendOTCEula = async (
	userToken: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	checkBoxes: any,
	isChecked: boolean,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsEula: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setIsButtonLoading(true);
	setIsError(false);
	const response = await fetch(API_ROUTES.AUTH.OTC.SEND_EULA, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			checkBoxId: checkBoxes[0].checkBoxId,
			isSelected: isChecked,
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.code) {
		setIsError(true);
		setIsButtonLoading(false);
	}
	setIsButtonLoading(false);
	setIsEula(false);
};

export const sendOTC = async (
	userToken: string,
	codeFrom: string,
	amountFrom: number,
	codeTo: string,
	amountTo: number,
	rate: number,
	setIsError: React.Dispatch<React.SetStateAction<boolean>>,
	setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setIsConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
	setIsSummary: React.Dispatch<React.SetStateAction<boolean>>,
	setIsErrorText: React.Dispatch<React.SetStateAction<string>>
) => {
	setIsButtonLoading(true);
	setIsError(false);
	const response = await fetch(API_ROUTES.AUTH.OTC.SEND_OTC, {
		method: "POST",
		headers: {
			Authorization: `Bearer_${userToken}`,
		},
		body: JSON.stringify({
			amountToSell: amountFrom,
			assetToSell: codeFrom,
			amountToBuy: amountTo,
			assetToBuy: codeTo,
			rate: rate,
		}),
	}).then((response) => {
		return response.json();
	});
	if (response.code) {
		setIsError(true);
		setIsErrorText(response.message);
		setIsButtonLoading(false);
		return;
	}
	setIsConfirmation(false);
	setIsSummary(true);
};
