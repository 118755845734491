import { Checkbox } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { TEulaCheckBox } from "../../../types/types";
import "../registration.css";

interface Props {
	mappedEula: TEulaCheckBox | undefined;
	checked: boolean[];
	setIsChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
	locale: "ru" | "en";
}
const RegistrationCheckboxes = ({ checked, setIsChecked, mappedEula }: Props) => {
	const { theme } = useContext(ThemeContext);

	const handleFirstCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked([e.target.checked, checked[1], checked[2]]);
	};

	const handleSecondCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked([checked[0], e.target.checked, checked[2]]);
	};

	return (
		<div className={"checkboxes"}>
			<div className={"firstCheckBox"}>
				<Checkbox
					sx={{
						"&.Mui-checked": {
							color: "#00BEB4 !important",
						},
						color:
							theme === "dark"
								? "#303030 !important"
								: "#EBECEE !important",
						borderRadius: "50% !important",
					}}
					key={"firstCheckBox"}
					checked={checked[0]}
					onChange={handleFirstCheckBoxChange}
				/>
				<h1
					className={"checkBox-h1" + (theme === "dark" ? "-dark" : "")}
					dangerouslySetInnerHTML={{
						__html: mappedEula?.checkBoxId['Agreement FREE2EX'] as string,
					}}
				></h1>
			</div>
			<div className={"secondCheckBox"}>
				<Checkbox
					key={"secondCheckBox"}
					checked={checked[1]}
					onChange={handleSecondCheckBoxChange}
					sx={{
						"&.Mui-checked": {
							color: "#00BEB4 !important",
						},
						color:
							theme === "dark"
								? "#303030 !important"
								: "#EBECEE !important",
						borderRadius: "50% !important",
					}}
				/>
				<h1
					className={"checkBox-h1" + (theme === "dark" ? "-dark" : "")}
					dangerouslySetInnerHTML={{
						__html: mappedEula?.checkBoxId['Personal data agreement'] as string,
					}}
				></h1>
			</div>
		</div>
	);
};

export default RegistrationCheckboxes;
